/* #THEME COLOR (variable overrides)
========================================================================== */
$color-primary:						#55ce5f;
$color-success:						#1dc958;
$color-info:						#21e2f3;
$color-warning:						#b8ff41;
$color-danger:						#c139fd;
$color-fusion:						darken(desaturate(adjust-hue($color-primary, 5), 80%), 25%); 

/* #GLOBAL IMPORTS
========================================================================== */
@import './src/scss/_imports/_theme-modules-import';

/* #Reset userselect
========================================================================== */
#myapp-0 {
	box-shadow: none !important;
}
#myapp-13 {
	box-shadow: 0 0 0 3px #000000;
}